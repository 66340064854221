import Vue from 'vue'
import App from './App.vue'


import {
    Dialog,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Tabs,
    TabPane,
    Message,
    Button,
    Input,
    Carousel,
    CarouselItem


} from 'element-ui';
import axios from 'axios'

Vue.prototype.$message = Message;
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Button)
Vue.use(Input)
Vue.use(Carousel)
Vue.use(CarouselItem)


let baseURL = '/'
axios.defaults.baseURL = baseURL
axios.interceptors.request.use(config => {
    return config
})

axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response.data);
        }
    },
    error => {
        return Promise.reject(error);
    }
);


Vue.prototype.$axios = axios;
Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#app')