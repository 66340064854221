<template>
    <div>
        <div class="video">
            <video loop="true" autoplay="autoplay" muted="" src="/static/video/pcb2.mp4"></video>
        </div>
        <div class="head">
            <div class="wrap clearfix">
                <div class="logo">
                    <a href="#"><img style="height:30px" src="/vue_static/img/logo.png" /></a>
                    <span>电子产业一站式赋能平台</span>
                </div>
                <div class="h-menu">
                    <ul>
                        <li>
                        <el-dropdown trigger="hover">
                            <div class="c-menu"><a href="https://www.fany-eda.com/" rel="nofollow" target="_blank">凡亿电路<i class="el-icon-caret-bottom"></i></a></div>
                            <el-dropdown-menu  slot="dropdown">
                                <el-dropdown-item><a href="https://www.fany-eda.com/layout/" rel="nofollow" target="_blank">PCB设计外包</a></el-dropdown-item>
                                <el-dropdown-item><a href="https://www.fany-eda.com/PCBzhiban/" rel="nofollow" target="_blank">PCB制板批量</a></el-dropdown-item>
                                <el-dropdown-item><a href="https://www.fany-eda.com/SMTtiepian/" rel="nofollow" target="_blank">SMT焊接贴片</a></el-dropdown-item>
                                <el-dropdown-item><a href="https://item.taobao.com/item.htm?id=684183873846" rel="nofollow" target="_blank">CIS库建设</a></el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown> 
                        </li>
                        <li>
                        <el-dropdown trigger="hover">
                            <div class="c-menu"><a href="https://www.fanyedu.com/">凡亿教育<i class="el-icon-caret-bottom"></i></a></div>
                            <el-dropdown-menu  slot="dropdown">
                                <el-dropdown-item><a href="https://www.fanyedu.com/fycourse">凡亿课堂</a></el-dropdown-item>
                                <el-dropdown-item><a href="https://www.fanyedu.com/live">凡亿直播</a></el-dropdown-item>
                                <el-dropdown-item><a href="https://www.fanyedu.com/ask">凡亿问答</a></el-dropdown-item>
                                <el-dropdown-item><a href="https://www.fanyedu.com/article">凡亿专栏</a></el-dropdown-item>
                                <el-dropdown-item><a href="https://www.fanyedu.com/doc">凡亿文库</a></el-dropdown-item>
                                <el-dropdown-item><a href="https://www.fanyedu.com/exam">考试认证</a></el-dropdown-item>
                                <el-dropdown-item><a href="https://job.pcbbar.com/" rel="nofollow" target="_blank">人才招聘</a></el-dropdown-item>

                            </el-dropdown-menu>
                        </el-dropdown> 
                        </li>
                        <li><a href="https://www.pcbbar.com" rel="nofollow" target="_blank">PCB联盟网</a></li>
                        <li><a href="https://www.iclib.com" rel="nofollow" target="_blank">IC封装网</a></li>
                        <li><a href="https://job.pcbbar.com" rel="nofollow" target="_blank">电子人才网</a></li>
                        <li>
                            <span @click="loginDialog = true">登录</span>
                            <label>/</label>
                            <span @click="registerDialog = true">注册</span>
                            <a><span class="icon icon-user"></span></a>
                        </li>

                    </ul>
                </div>
                <div class="write">
                    <el-dropdown placement="bottom-start">
                        <el-button class="write-btn" type="primary">
                            <span class="icon-w">创作<i class="el-icon"></i></span>
                        </el-button>
                        <el-dropdown-menu class="drop" slot="dropdown">
                            <div class="tit">
                                <ul class="ul-btn-list">
                                    <li>
                                        <a href="https://www.fanyedu.com/account/article/add">
                                            <div class="img img1"></div>
                                            <p>写文章</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.fanyedu.com/account/course/add">
                                            <div class="img img2"></div>
                                            <p>发视频</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.fanyedu.com/account/ask/add">
                                            <div class="img img3"></div>
                                            <p>提问题</p>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.fanyedu.com">
                                            <div class="img img4"></div>
                                            <p>传资源</p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <!--
              <div class="q-list">
                <h3>创作活动<a to="/">更多</a></h3>
                <ul>
                  <li>
                    <a to="/"><span>#</span>PCB课程创作活动集结，大额补贴来临</a>
                  </li>
                  <li>
                    <a to="/"><span>#</span>电源环路稳定性评价方法创作招募</a>
                  </li>
                </ul>
              </div>
              -->
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <div class="box">
            <div class="left">
                <div class="sield">
                    <el-carousel class="carousel" height="570px">

                        <el-carousel-item v-for="(item,key) in banner" :key="key">
                            <a :href="item.url" target="_blank" v-if="item.url">
                                <img :src="item.img">
                            </a>
                            <img :src="item.img" v-else>
                        </el-carousel-item>                 
                      
                    </el-carousel>
                </div>
            </div>
            <div class="right">
                <div v-if="tab=='login'">
                    <el-tabs v-model="login">
                        <el-tab-pane label="扫码登录" name="wx">
                            <div class="login-box box1">
                                <div class="ewm">
                                    <div class="img"><img :src="wxqr" /></div>
                                    <p>使用微信扫描二维码登录</p>
                                </div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="账号登录" name="user">
                            <div class="login-box box1">
                                <ul>
                                    <li>
                                        <el-input class="input" v-model="form.username" placeholder="请输入登录手机号/用户名/邮箱"></el-input>
                                    </li>
                                    <li>
                                        <el-input class="input" v-model="form.password" type="password" placeholder="请输入密码" suffix-icon="el-icon-view"></el-input>
                                    </li>
                                </ul>
                            </div>
                            <div class="login-box">
                                <ul>
                                    <li class="flex alignItems">
                                        <label class="lbl-radio" ><span class="on">24小时内自动登录</span></label>
                                        <a class="txt" @click="tab='retrieve'">忘记密码</a>
                                        <a href="https://www.fanyedu.com/kf" class="txt">遇到问题</a>
                                    </li>
                                    <li class="flex alignItems">
                                        <label class="lbl-radio" @click="xy = !xy"><span :class="xy ? 'on' : ''">我已阅读,并同意<a href="https://www.fanyedu.com/page/privacy" target="_blank">《隐私政策》</a>,<a href="https://www.fanyedu.com/page/agreement" target="_blank">《注册协议》</a> </span></label>
                                    </li>
                                    <li>
                                        <div class="btn">
                                            <el-button type="primary" round @click="form.type='user';userLogin()">立即登录</el-button>
                                        </div>
                                        <div class="btn">
                                            <el-button type="text" @click="tab='reg'">快速注册</el-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="验证码登录" name="mobile">
                            <div class="login-box box1">
                                <ul>
                                    <li>
                                        <el-input class="input" v-model="form.mobile" placeholder="请输入登录手机号"></el-input>
                                    </li>
                                    <li>
                                        <el-input class="input" v-model="form.sms" placeholder="请输入短信验证码">
                                            <el-button slot="append" class="sms" :class="smstime>0?'smson':''" @click="sendsms(form.mobile,'login')">{{smstime!=0?smstime:'获取验证码'}}</el-button>
                                        </el-input>
                                    </li>
                                </ul>
                            </div>
                            <div class="login-box">
                                <ul>
                                    
                                    <li class="flex alignItems">
                                        <label class="lbl-radio"><span class="on">24小时内自动登录</span></label>
                                        <a class="txt" @click="tab='retrieve'">忘记密码</a>
                                        <a href="https://www.fanyedu.com/kf" class="txt">遇到问题</a>
                                    </li>
                                    <li class="flex alignItems">
                                        <label class="lbl-radio" @click="xy = !xy"><span :class="xy ? 'on' : ''">我已阅读,并同意<a href="https://www.fanyedu.com/page/privacy" target="_blank">《隐私政策》</a>,<a href="https://www.fanyedu.com/page/agreement" target="_blank">《注册协议》</a> </span></label>
                                    </li>
                                    <li>
                                        <div class="btn">
                                            <el-button type="primary" round @click="form.type='sms';userLogin()">立即登录</el-button>
                                        </div>
                                        <div class="btn">
                                            <el-button type="text" @click="tab='reg'">快速注册</el-button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </el-tab-pane>
                    </el-tabs>

                </div>
                <div v-if="tab=='reg'">
                    <el-tabs value="user-register">
                        <el-tab-pane label="快速注册" name="user-register">
                            <div class="login-box box1">
                                <ul>
                                    <li>
                                        <el-input class="input" v-model="reg.mobile" placeholder="请输入注册手机号"></el-input>
                                    </li>
                                    <li>
                                        <el-input class="input" v-model="reg.sms" placeholder="请输入短信验证码">
                                            <el-button slot="append" class="sms" :class="smstime>0?'smson':''" @click="sendsms(reg.mobile,'reg')">{{smstime>0?smstime:'获取验证码'}}</el-button>
                                        </el-input>
                                    </li>
                                    <li>
                                        <el-input class="input" v-model="reg.nickname" placeholder="请输入昵称"></el-input>
                                    </li>
                                    <li>
                                        <el-input class="input" type="password" v-model="reg.password" placeholder="请输入密码" suffix-icon="el-icon-view"></el-input>
                                    </li>
                                </ul>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="login-box">
                        <ul>

                            <li class="flex alignItems">
                                <label class="lbl-radio" @click="xy = !xy"><span :class="xy ? 'on' : ''">我已阅读,并同意<a href="https://www.fanyedu.com/page/privacy" target="_blank">隐私政策</a>,<a href="https://www.fanyedu.com/page/agreement" target="_blank">注册协议</a> </span></label>
                                <a class="txt" @click="tab='login'">返回账号登录</a>
                            </li>
                            <li>
                                <div class="btn">
                                    <el-button type="primary" round @click="onReg()">立即注册</el-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="tab=='retrieve'">
                    <el-tabs value="retrieve">
                        <el-tab-pane label="忘记密码" name="retrieve">
                            <div class="login-box box1">
                                <ul>
                                    <li>
                                        <el-input class="input" v-model="ret.mobile" placeholder="请输入注册手机号"></el-input>
                                    </li>
                                    <li>
                                        <el-input class="input" v-model="ret.sms" placeholder="请输入短信验证码">
                                            <el-button slot="append" class="sms" :class="smstime>0?'smson':''" @click="sendsms(ret.mobile,'retrieve')">{{smstime>0?smstime:'获取验证码'}}</el-button>
                                        </el-input>
                                    </li>
                                    <li>
                                        <el-input class="input" v-model="ret.password" type="password" placeholder="请输入新密码"></el-input>
                                    </li>
                                </ul>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                    <div class="login-box">
                        <ul>

                            <li class="flex alignItems">
                                <label class="lbl-radio" @click="xy = !xy"><span :class="xy ? 'on' : ''">同意<a href="https://www.fanyedu.com/page/privacy" target="_blank">隐私政策</a>,<a href="https://www.fanyedu.com/page/agreement" target="_blank">注册协议</a> </span></label>
                                <a class="txt" @click="tab='login'">返回账号登录</a>
                            </li>
                            <li>
                                <div class="btn">
                                    <el-button type="primary" round @click="onRetrieve()">重设密码</el-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="login-box login-bottom">
                    <ul v-if="tab!='reg'">
                        <li>
                            <a @click="tab='login';login='wx'"><img src="/vue_static/img/wx.png" /></a>
                        </li>                       
                    </ul>
                    <p>
                        <a href="https://www.fanyedu.com/page/privacy" target="_blank">隐私政策</a>
                        <a href="https://www.fanyedu.com/page/agreement" target="_blank">注册协议</a>
                    </p>
                    <p>账号可以同时登录</p>
                    <p><a href="https://www.fanyedu.com">凡亿课堂</a> · <a href="https://www.pcbbar.com">PCB联盟网</a> · <a href="https://job.pcbbar.com">电子人才网</a></p>
                </div>
                <div class="login-type" :class="login=='wx' ? 'on' : ''" @click="tab='login';login=login=='wx'?'user':'wx'"></div>
            </div>
        </div>
        <div class="foot">
            湖南凡亿智邦电子科技有限公司
            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=43019002001256">湘公网安备 43019002001256号</a>
        </div>

        <el-dialog :visible.sync="bindshow" width="30%" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
            <el-tabs value="bind" class="bind">
                <el-tab-pane label="账号绑定" name="bind">
                    <div class="login-box box1">
                        <ul>
                            <li>
                                <el-input class="input" v-model="bind.nickname" placeholder="请输入昵称"></el-input>
                            </li>
                            <li>
                                <el-input class="input" v-model="bind.mobile" placeholder="请输入注册手机号"></el-input>
                            </li>
                            <li>
                                <el-input class="input" v-model="bind.sms" placeholder="请输入短信验证码">
                                    <el-button slot="append" class="sms" :class="smstime>0?'smson':''" @click="sendsms(bind.mobile,'bind')">{{smstime>0?smstime:'获取验证码'}}</el-button>
                                </el-input>
                            </li>
                        </ul>
                    </div>
                    <div class="login-box">
                        <ul>
                            <li>
                                <div class="btn">
                                    <el-button type="primary" round @click="onBind()">立即绑定</el-button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </el-tab-pane>

            </el-tabs>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            banner:[],
            bindshow: false,
            tab: "login",
            login: "wx",
            isLogin: true, //7天自动登录
            xy:false,

            reg: {},
            ret: {},
            bind: {
                nickname:''
            },

            wxqr: null,
            ticket: "",
            form: {},
            smstime: 0,
            backurl:window.BACKURL || 'https://www.fanyedu.com'
        };
    },
    async created(){
        const result = await this.$axios.get('https://api.fanyedu.com/base/getAd/43')
        this.banner=result.data.multiple
    },
    mounted() {
        if (window.ISQQLOGIN) {
            this.qqlogin();
        }else{
          this.wxlogin();
        }        
    },

    methods: {
        async onBind() {
            if (!this.ismobile(this.bind.mobile)) {
                this.$message.error("您输入的手机号码有误");
                return;
            }
            if (!/^\d{4}$/.test(this.bind.sms)) {
                this.$message.error("您输入的手机验证码有误");
                return;
            }
            const url =
                this.bind.type == "qq" ? "https://login.pcbbar.com/qqlogin/bind" : "/wxlogin/bind";

            const result = await this.$axios.post(url, this.bind);
            if (result.code == 200) {
                this.$message.success("绑定成功,正在为您登录...");
                this.getMultiScripts(result.data.list);
            } else {
                this.$message.error(result.message);
            }
        },
        async userLogin() {
            if(!this.xy){
                this.$message.error("请勾选服务协议");
                return;
            }
            if (this.form.type == "sms") {
                if (!this.ismobile(this.form.mobile)) {
                    this.$message.error("您输入的手机号码有误");
                    return;
                }
                if (!/^\d{4}$/.test(this.form.sms)) {
                    this.$message.error("您输入的手机验证码有误");
                    return;
                }
            }
            if (this.form.type == "user") {
                if (this.form.username.length < 3) {
                    this.$message.error("您输入的登录名有误");
                    return;
                }
                if (this.form.password.length < 6) {
                    this.$message.error("您输入的密码有误");
                    return;
                }
            }
            const result = await this.$axios.post("/login", this.form);
            if (result.code == 200) {
                this.$message.success("登录成功,正在为您前往...");
                this.getMultiScripts(result.data.list);
            } else {
                this.$message.error(result.message);
            }
        },

        async qqlogin() {
            const result = await this.$axios.get("https://login.pcbbar.com/qqlogin/push");
            if (result.code != 200) {
                return;
            }
            if (!result.data.isbind) {
                this.bind.nickname = result.data.nickname || '';
                this.bind.type = "qq";
                this.bindshow = true;
                return;
            } else {
                this.$message.success("登录成功,正在为您前往...");
                this.getMultiScripts(result.data.list);
            }
        },

        async wxlogin() {
            let result = await this.$axios.get("/wxlogin/qr");
            this.wxqr = result.data.qr;
            this.ticket = result.data.ticket;
            this.wxloginpush();
        },

        async wxloginpush() {
            let result = await this.$axios.get(
                "/wxlogin/push?ticket=" + this.ticket
            );
            if (result.code != 200) {
                setTimeout(() => {
                    this.wxloginpush();
                }, 1000);
                return;
            }
            if(!result.data.isbind){
                this.bind.nickname = result.data.nickname || '';
                this.bind.type = "wx";
                this.bindshow = true;
                return;
            }

            if (result.data.isbind) {
                this.$message.success("登录成功,正在为您前往...");
                this.getMultiScripts(result.data.list);
            }
        },

        async onReg() {
            if(!this.xy){
                this.$message.error("请勾选服务协议");
                return;
            }
            if (!this.ismobile(this.reg.mobile)) {
                this.$message.error("您输入的手机号码有误");
                return;
            }
            if (!/^\d{4}$/.test(this.reg.sms)) {
                this.$message.error("您输入的手机验证码有误");
                return;
            }

            if (!/^[\d\D]{6,15}$/.test(this.reg.password)) {
                this.$message.error("您输入的密码有误,密码允许6位至16位");
                return;
            }
            const result = await this.$axios.post("/reg", this.reg);
            if (result.code == 200) {
                this.$message.success("注册成功");
                this.tab = "login";
            } else {
                this.$message.error(result.message);
            }
        },
        async onRetrieve() {
            if(!this.xy){
                this.$message.error("请勾选服务协议");
                return;
            }
            if (!this.ismobile(this.ret.mobile)) {
                this.$message.error("您输入的手机号码有误");
                return;
            }
            if (!/^\d{4}$/.test(this.ret.sms)) {
                this.$message.error("您输入的手机验证码有误");
                return;
            }
            if (!/^[\d\D]{6,15}$/.test(this.ret.password)) {
                this.$message.error("您输入的密码有误,密码允许6位至16位");
                return;
            }
            const result = await this.$axios.post("/retrieve", this.ret);
            if (result.code == 200) {
                this.$message.success("修改成功");
                this.tab = "login";
            } else {
                this.$message.error(result.message);
            }
        },
        getDomain(url){
            console.log(url)
            let domain = url.split('/')
            if( domain[2] ) {
                domain = domain[2]
            } else {
                domain = ''
            }
            return domain
        },
        bethDomain(d1){
            let backDomain=this.getDomain(this.backurl)
            if(d1==backDomain){
                return true
            }
            if(d1=='api.fanyedu.com' && backDomain=='www.fanyedu.com'){
                return true
            }
        },
        getMultiScripts(list) {
            list.forEach((src) => {
                if(this.bethDomain(this.getDomain(src))){
                    window.location.href=src+'&backurl='+this.backurl
                    return
                }
            });
        },
        async sendsms(mobile, type) {
            if (this.smstime > 0) return false;
            if (!this.ismobile(mobile)) {
                this.$message.error("您输入的手机号码有误");
                return false;
            }
            const result = await this.$axios.post("/sendsms", { mobile, type });
            if (result.code == 200) {
                this.$message.success("短信已发送，注意查收");
                this.smstime = 60;
                let interval = setInterval(() => {
                    this.smstime--;
                    if (this.smstime == 0) {
                        clearInterval(interval);
                    }
                }, 1000);
                return true;
            } else {
                this.$message.error("发送失败:" + result.message);
                return false;
            }
        },

        ismobile(mobile) {
            return /^1\d{10}$/.test(mobile);
        },
    },
};
</script>


<style scoped>
.el-dropdown-menu {
    padding: 10px 0!important;
    margin: 5px 0!important
}

.el-dropdown-menu__item {
    padding: 0 20px
}
.h-menu li i {
    color: #fff
}
a{ cursor: pointer;}
.bind {
    padding-bottom: 20px;
}
.sms {
    margin: 0;
}
.smson {
    color: #333;
}
.foot {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    line-height: 50px;
    background: rgba(255, 255, 255, 0.9);
    text-align: center;
}
.video {
    overflow: hidden;
    background: #000000;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
}
.video::after {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1;
}
.video video {
    width: 100%;
}

.box {
    width: 1200px;
    border-radius: 10px;
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 1);
    height: 620px;
    display: flex;
}
.box .right {
    width: 400px;
    position: relative;
    height: 620px;
}
.box .left {
    height: 620px;
    width: 800px;
    border-right: 1px solid #e4e7ed;
}
.box .left .sield {
    width: 750px;
    height: 570px;
    margin: 25px auto 25px 25px;
    background: #4ea836;
}
.carousel{ height: 570px;}
.box .left .sield img{ width: 100%;}
img {
    display: block;
}
.head {
    height: 49px;
    background-color: #333;
    color: #fff;
    font-size: 14px;
}
.head .logo {
    float: left;
    display: flex;
    align-items: center;
    height: 48px;
}
.head .logo span {
    padding-left: 15px;
    margin-left: 15px;
    font-size: 14px;
    line-height: 13px;
    border-left: 1px solid #fff;
}
.h-menu {
    float: right;
}
.h-menu li {
    display: inline-block;
    line-height: 48px;
    padding: 0 25px;
    position: relative;
}
.h-menu li span {
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
}
.h-menu li .headpic {
    width: 30px;
    height: 30px;
    margin-left: 5px;
}
.h-menu li a {
    color: #fff;
}
.h-menu li span:hover,
.h-menu li a:hover {
    color: #4ea836;
}
.h-menu li:last-child {
    padding-right: 0;
    padding-left: 35px;
    margin-left: 10px;
}
.h-menu li label {
    display: inline-block;
    vertical-align: middle;
}
.h-menu li:last-child::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1px;
    height: 14px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -7px;
    left: 0;
}
.icon {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: #dcdcdd;
    vertical-align: middle;
    margin-left: 10px;
}
.icon.icon-user {
    background-image: url(/vue_static/img/icon1.png);
    background-position: center center;
    background-repeat: no-repeat;
}
.write {
    border: 0;
    position: absolute;
    top: 50%;
    margin-top: -16px;
    left: 100%;
    white-space: nowrap;
    margin-left: 35px;
    cursor: pointer;
}
.write-btn {
    padding: 0 14px;
    border-radius: 40px;
    border: 0;
    background-image: linear-gradient(to right, #f85d2b, #fe9527);
    line-height: 32px;
}
.write-btn .icon-w {
    display: inline-block;
    padding-left: 20px;
    background: url(/vue_static/img/icon2.png) no-repeat left center;
}
.write-btn .icon-w .el-icon {
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    width: 9px;
    height: 8px;
    background: url(/vue_static/img/icon3.png) no-repeat center center;
}
.drop {
    padding: 18px 20px;
    width: 260px;
}
.drop .tit {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #e8e8e8;
}

.q-list h3 {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 10px;
}
.q-list h3 a {
    font-size: 14px;
    float: right;
    color: #999999;
    display: block;
    padding-right: 10px;
    background: url(/vue_static/img/icon8.png) no-repeat right center;
}
.q-list li {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: 24px;
}
.q-list a {
    color: #999999;
}
.q-list a:hover {
    color: #4ea836;
}
.q-list li a span {
    color: #4ea836;
}

.header .menu li {
    font-size: 16px;
    display: inline-block;
    margin-left: 40px;
    position: relative;
}
.header .menu li.active a {
    font-weight: bold;
    color: #4ea836;
}
.header .menu li.active::after {
    content: "";
    bottom: -22px;
    display: block;
    width: 24px;
    height: 4px;
    position: absolute;
    left: 50%;
    margin-left: -12px;
    background: #4ea836;
    border-radius: 4px;
}
.header {
    line-height: 50px;
    padding: 20px 0;
    background: #ffffff;
}
.header .logo {
    margin-right: 24px;
}
.search .el-input {
    font-size: 16px;
    border: 1px solid #4ea836;
    border-radius: 5px;
}
.search .btn {
    width: 64px;
    text-align: center;
    color: #222;
}

.dialog-tit {
    line-height: 76px;
    padding: 0 30px;
    border-bottom: 1px solid #e8e8e8;
}
.login-box {
    padding: 0 30px;
}
.login-box li {
    margin-bottom: 20px;
}
.lbl-radio {
    cursor: pointer;
    flex: 1;
}
.lbl-radio span {
    display: inline-block;
    padding-left: 18px;
    background: url(/vue_static/img/icon50.png) no-repeat left center;
}
.lbl-radio a {
    color: #4ea836;
}
.lbl-radio span.on {
    background: url(/vue_static/img/icon50-1.png) no-repeat left center;
}
.login-box.box1 {
    padding-top: 30px;
}
.login-box .txt {
    display: inline-block;
    font-size: 14px;
    color: #999;
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #e8e8e8;
    height: 14px;
    line-height: 14px;
}
.login-box .txt:hover {
    color: #4ea836;
}
.login-box .txt:last-child {
    padding-right: 0;
    margin-right: 0;
    border-right: 0;
}
.btn .el-button {
    font-size: 18px;
    color: #4ea836;
}
.btn .el-button--primary {
    background-image: linear-gradient(to right, #4ea836, #63c449);
    border-color: #4ea836;
    width: 100%;
    line-height: 56px;
    height: 56px;
    padding: 0;
    border-radius: 50px;
    font-size: 18px;
    color: #fff;
}
.btn {
    text-align: center;
    margin-bottom: 10px;
}
.login-bottom {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    text-align: center;
}
.login-bottom img {
    margin: 0 auto;
}
.login-bottom ul {
    margin-bottom: 10px;
}

.login-bottom ul {
    display: flex;
    justify-content: space-evenly;
    padding: 0 30px;
}
.login-bottom p {
    color: #adadad;
    line-height: 25px;
}
.login-bottom p a{ margin: 0 10px;}
.login-bottom a{ color: #999;}
.login-type {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 69px;
    height: 69px;
    background: url(/vue_static/img/l-ewm.png);
    cursor: pointer;
}
.login-type.on {
    background: url(/vue_static/img/l-pc.png);
}
.login-box .ewm {
    height: 331px;
    text-align: center;
}
.login-box .ewm .img {
    width: 229px;
    padding: 5px;
    background: #fff;
    margin: 20px auto;
}

* {
    margin: 0;
    padding: 0;
}

body,
html {
    font-family: -apple-system, Helvetica, sans-serif;
    color: #222222;
    font-size: 14px;
}

a,
a:link {

    text-decoration: none;
}

a:hover {
    color: #4ea836;
}

.bg {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f5f5f5;
}

.wrap {
    width: 1200px;
    margin: 0 auto;
    position: relative;
}

input {
    width: 100%;
    border: none;
    background: none;
    box-sizing: border-box;
}

ul,
li {
    list-style: none;
    padding: 0;
    margin: 0;
}

div,
textarea {
    box-sizing: border-box;
}

img {
    height: inherit;
}

.clear {
    clear: both;
}

.clear::after {
    content: "";
    display: block;
    clear: both;
}

.clearfix {
    zoom: 1;
}

.clearfix:after {
    content: "";
    height: 0;
    display: block;
    visibility: hidden;
    font-size: 0;
    clear: both;
}

.space_sp {
    height: 10px;
    background-color: #f6f7fb;
}

button {
    border: none;
    background-color: transparent;
}

button:after {
    border: none;
}

.mask {
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>

